<template>
  <travio-center-container :pageTitle="pageTitle">
    <vx-card>
      <div class="flex flex-wrap items-center mt-3 mb-4">
        <vs-avatar :src="form.imageUrl" icon="cloud_upload" @click="$refs.rewardImageInput.click()" size="70px" :disabled="readOnly" class="mr-4 mb-4 reward-avatar" />
        <div>
          <input type="file" hidden id="rewardImageInput" accept="image/*" ref="rewardImageInput" v-on:change="handleFileSelection"/>
          <vs-button @click="$refs.rewardImageInput.click()" class="mr-4 sm:mb-0 mb-2" >Select Image</vs-button>
          <vs-button @click="removeRewardImage" type="border" color="danger" :disabled="readOnly" >Remove</vs-button>
          <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <label class="">Name*</label>
          <vs-input class="w-full" v-model="form.name" v-validate="'required|max:50'" name="Name" :disabled="readOnly" />
          <span class="text-danger text-sm">{{ errors.first('Name') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <label class="">Description*</label>
          <vs-input class="w-full" v-model="form.description" v-validate="'required|max:200'" name="Description" :disabled="readOnly" />
          <span class="text-danger text-sm">{{ errors.first('Description') }}</span>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-full">
          <label class="">Terms</label>
          <vs-textarea 
            name="Terms" rows="2" v-model="form.terms" v-validate="'max:1000'" class="w-full mb-4" :disabled="readOnly" />
          <span class="text-danger text-sm">{{ errors.first('Terms') }}</span>
        </div>
      </div>
      
      <div class="vx-row mb-4">
        <div class="vx-col w-1/2">
          <label class="">Modifier Type</label>
          <v-select v-model="form.isPercentage" name="Modifier Type" :options="modifierOptions" :reduce="(x) => x.code" v-validate="'required'" :clearable="false" :disabled="readOnly" />
          <span class="text-danger text-sm">{{errors.first("Modifier Type")}}</span>
        </div>
        <div class="vx-col w-1/2">
          <label class="">Modifier Value</label>
          <vs-input v-model.number="form.amount" class="w-full" type="number" min="0" name="Modifier Value" v-validate="'required|decimal|greaterThanZero'" :disabled="readOnly" />
          <span class="text-danger text-sm">{{errors.first("Modifier Value")}}</span>
        </div>
      </div>
     
      <div class="vx-row flex flex-wrap justify-start mb-8">
        <div class="vx-col w-1/2">
          <label class="">Maximum Reward Amount</label>
          <vs-input class="w-full" v-model.number="form.maxAmount" type="number" min="0" v-validate="''" name="Maximum Reward Amount" :disabled="readOnly" />
          <span class="text-danger text-sm">{{ errors.first('Maximum Reward Amount') }}</span>
        </div>
        <div class="mt-8">{{appDefaultCurrency}}</div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <vs-checkbox class="w-full" v-model="form.isActive" :disabled="readOnly" >Active</vs-checkbox>
        </div>
      </div>
      
      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button @click='onCancel' color="danger">Cancel</vs-button>
        <vs-button @click='onSave' class="ml-4" :disabled="readOnly" >Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

export default {
  components: {
    vSelect
  },
  props: {
    applicationId: { required: true },
    rewardId: { default: 0 }
  },
  data () {
    return {
      form: new Form({
        id: 0,
        name: '',
        description: null,
        terms: null,
        imageUrl: null,
        imageInput: null,
        amount: 0,
        isPercentage: true,
        maxAmount: 0,
        isActive: true,
        shouldRemoveImage: false, // applies during edit, when existing image is removed without replacement
        fileExtension: '', // applies to edit only ignored in the backend if fileinput is not null
      }),
      modifierOptions: [
        { code: true, label: 'Percentage'},
        { code: false, label: 'Amount'},
      ],
      appDefaultCurrency: '',
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    pageTitle () {
      return this.rewardId ? 'Edit Reward' : 'Add Reward'
    },
    readOnly() {
      if (this.$route.meta.readOnly) {
        return true
      }
      return false
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  created () {
    if (!this.appHasFeature('rewards')) {
      this.$router.push('/error-404')
    }

    this.$validator.extend(
      'greaterThanZero',{
      getMessage: field =>  field + ' needs to be greater than zero.',
      validate: (value) => {
        // value must be > zero
        if (value > 0 ) return true;
        return false;
      }
    });
  },
  async mounted () {
    const formDataPromise = this.$http.get(`api/rewardRules/apps/${this.applicationId}/rewards/formdata`)
    this.$vs.loading()
    try {
      if (this.rewardId) {
        // Edit
        const rewardDataPromise = this.$http.get(`api/rewardRules/apps/${this.applicationId}/rewards/${this.rewardId}`)
        const [formDataResponse, rewardDataResponse] = await Promise.all([formDataPromise, rewardDataPromise])
        this.form = new Form(Object.assign(this.form.data(), rewardDataResponse.data))
        this.appDefaultCurrency = formDataResponse.data.appDefaultCurrency
      } else {
        const formDataResponse = await formDataPromise
        this.appDefaultCurrency = formDataResponse.data.appDefaultCurrency
      }
    }
    catch (error) {
      this.$_notifyFailure(error)
    }
    this.$vs.loading.close();

  },
  methods: {
    async onSave () {

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
      this.$vs.loading()
      try {

        const payload = this.form.data()
        const keys = Object.keys(payload);
        let formData = new FormData();
        keys.forEach((key, index) => {
          formData.append(key, payload[key])
          // Appending null to formdata converts it to 'null', delete the key to set it to null
          if (payload[key] == null) {
            formData.delete(key)
          }
        });

        if (this.rewardId) {
          const response = await this.$http.put(`api/rewardRules/apps/${this.applicationId}/rewards`, 
            formData, 
            { headers: { 'Content-Type': 'multipart/form-data'} }
          )
          this.$_notifySuccess('Successfully updated reward');
        } else {
          const response = await this.$http.post(`api/rewardRules/apps/${this.applicationId}/rewards`,
            formData, 
            { headers: { 'Content-Type': 'multipart/form-data'} }
          )
          this.$_notifySuccess('Successfully created reward');
        }
        this.onCancel()

        this.$vs.loading.close()

      } catch (error) {
        this.$_notifyFailureByResponseData(error.response.data)
      }
      this.$vs.loading.close()
    },
    handleFileSelection() {
      const currentImage = this.$refs.rewardImageInput.files[0];
      if (!currentImage) return
      this.form.imageInput = currentImage
      this.form.imageUrl = URL.createObjectURL(currentImage)
      this.form.shouldRemoveImage = false
    },
    removeRewardImage () {
      this.$refs.rewardImageInput.value = '';
      this.form.imageUrl = '';
      this.form.shouldRemoveImage = true
    },

    backToList () {
      this.$router.push({name: 'application-tools-reward-list', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
    onCancel () {
      this.backToList()
    }
  }
}
</script>

<style>
  .reward-avatar .con-vs-avatar,
  .reward-avatar .vs-avatar--con-img {
      border-radius: 5%;
    }
</style>